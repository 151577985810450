import { get, post } from '../request'

//获取专题列表
export const getList = (data) =>
	get('/api/market/advertising/getSubjectList', data)

//新增或编辑专题
export const edit = (data) =>
	post('/api/market/advertising/updateSubject', data)

//获取专题详情
export const detail = (data) =>
	get('/api/market/advertising/getSubjectDetail', data)

//删除专题
export const del = (data) => post('/api/market/advertising/deleteSubject', data)
